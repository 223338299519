
import * as React from "react"
import Template from "components/template";
import * as config from 'utils/configs/rocsolutions';

// markup
const RocsolutionsPage = () => {
  return (
    <Template config={config} />
  )
}

export default RocsolutionsPage
